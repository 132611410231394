<template>
  <div>
    <input
      type="file"
      accept="image/*"
      style="display: none"
      @change="handleVoucherUpload"
      multiple
      ref="voucher"
    />
    <span style="margin-right: 8px; color: #333333; font-weight: bolder; font-size: 15px"
      >线下付款凭证</span
    >
    <el-button
      type="primary"
      style="font-size: 14px"
      :loading="flag.uploadvouchering"
      size="mini"
      @click="$refs.voucher.click()"
    >
      <i class="el-icon-upload2" style="margin-right: 2px; font-size: 16px"></i>
      批量上传
    </el-button>
    <span style="margin-left: 14px; color: #333333">注明：最多一次可上传10张</span>

    <span style="color: red; margin-left: 14px; font-size: 18px; font-weight: bold"
      >技术服务费回单凭证不用上传
    </span>
    <!-- 凭证列表 -->
    <div class="voucherWrap">
      <div
        class="item"
        v-for="item in paginationVoucherList"
        :key="item.EvidenceID"
        :style="{ width: hideUploadFileBtn === true ? '20%' : '' }"
      >
        <i
          class="delete el-icon-delete"
          title="删除凭证"
          @click="deleteVoucher(item.EvidenceID)"
        ></i>
        <el-image
          class="uploadImg"
          :src="item.EvidenceImgUrl"
          :preview-src-list="[item.EvidenceImgUrl]"
        >
          <div
            slot="error"
            class="image-slot"
            style="display: flex; justify-content: center; margin-top: 30px"
          >
            <i class="el-icon-picture-outline" style="font-size: 50px"></i>
          </div>
        </el-image>
        <!-- <el-tooltip :content="item.FileName">
                    <p style="margin-bottom: 8px;" @click="downloadVoucher(item.EvidenceImgUrl)">{{ item.FileName }}</p>
                </el-tooltip> -->
      </div>
      <div v-if="currentVoucherList.length == 0" class="placeholder">暂无凭证</div>
    </div>
    <!-- 分页 -->
    <el-pagination
      background
      style="margin-top: 16px"
      @current-change="setPagination"
      :current-page.sync="pagination.page"
      :page-size="pagination.pagesize"
      v-if="flag.isloading"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>

    <div class="voucherBtn" v-if="hideUploadFileBtn === true ? false : true">
      <el-button style="margin-bottom: 16px" @click="uploadVoucher(false)"
        >关闭</el-button
      >
      <el-button
        :disabled="currentVoucherList.length < 1"
        style="margin-bottom: 16px"
        @click="preserveVoucher"
        type="primary"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getEvidence,
  preserveEvidence,
  addEvidence,
} from "@/api/waybill/pendingManage/offlineVoucher";
import { upLoadImgByUrl } from "@/api/common/common";
import { deleteEvidence } from "@/api/waybill/waybillManage/waybillRepository";
// import { upLoadImgByUrl } from '@/api/transport/driverManage/bulkImport'

export default {
  data() {
    return {
      pagination: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
      flag: {
        uploadvouchering: false, //上传凭证中
        isloading: true,
      },
      currentVoucherList: [], //当前选中的凭证
      paginationVoucherList: [], // 分页展示的凭证
    };
  },
  props: ["hideUploadFileBtn"],
  computed: {
    ...mapGetters(["getInvoiceTaskID"]),
  },
  methods: {
    // 设置分页, 获取相关展示数据
    setPagination(page, total) {
      setTimeout(() => {
        this.pagination.page = page;
        if (total || total == 0) this.pagination.total = total;
      }, 100);
      this.paginationVoucherList = this.currentVoucherList.slice(
        (page - 1) * 10,
        page * 10
      );
    },

    // 获取更新凭证列表
    getUploadFile() {
      this.currentVoucherList = [];
      getEvidence(
        {
          pageSize: 1000,
          pageIndex: 1,
        },
        this.getInvoiceTaskID
      ).then((res) => {
        let { DataList, PageIndex, TotalCount } = res.data;
        this.currentVoucherList = DataList;
        this.$emit(
          "showComfirmPay",
          this.currentVoucherList && this.currentVoucherList.length == 0 ? true : false
        );
        this.currentVoucherList;
        this.setPagination(PageIndex, TotalCount);
        this.flag.uploadvouchering = false;
      });
    },

    //上传凭证
    handleVoucherUpload(e) {
      if (e.srcElement.files.length > 10) {
        this.$message.warning("最多可同时上传10个文件");
        this.$refs.voucher.value = null;
        return;
      }
      this.flag.uploadvouchering = true;
      let fileLength = e.srcElement.files.length;

      for (let i = 0; i < fileLength; i++) {
        this.imageToBase64(e.srcElement.files[i]);
      }
    },

    // 本地文件转换为base64进行上传
    imageToBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.flag.isloading = false;
        upLoadImgByUrl({
          fileBase64: reader.result,
          FileType: "Receipt",
        })
          .then((res) => {
            this.currentVoucherList.push({
              EvidenceID: 0 - this.currentVoucherList.length,
              EvidenceImgUrl: res.data,
            });
            this.flag.uploadvouchering = false;

            this.setPagination(
              Math.ceil(this.currentVoucherList.length / 10),
              this.currentVoucherList.length
            );

            this.$emit("showComfirmPay", false);
          })
          .catch((e) => {
            console.log("本地文件转换为base64进行上传-失败", e);
          })
          .finally(() => {
            this.$refs.voucher.value = null;
            this.flag.isloading = true;
          });
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },

    //删除一个凭证
    deleteVoucher(fileUrlID) {
      this.$confirm("是否确定删除该凭证", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (fileUrlID > 0) {
            deleteEvidence({ evidenceID: fileUrlID }).then((res) => {});
          }

          let findFileIndex = this.currentVoucherList.findIndex(
            (item) => item.EvidenceID == fileUrlID
          );
          this.currentVoucherList.splice(findFileIndex, 1);
          // 删除完最后一页的内容， 重新设置当前页为最后一页
          this.pagination.page =
            this.pagination.page > Math.ceil(this.currentVoucherList.length / 10)
              ? Math.ceil(this.currentVoucherList.length / 10)
              : this.pagination.page;
          this.setPagination(this.pagination.page, this.currentVoucherList.length);

          this.$emit("showComfirmPay");
        })
        .catch();
    },

    // 保存线下付款凭证
    preserveVoucher() {
      let urlList = [];
      this.currentVoucherList.forEach((item) => {
        urlList.push(item.EvidenceImgUrl);
      });

      preserveEvidence(
        {
          filesUrl: urlList,
        },
        this.getInvoiceTaskID
      )
        .then((res) => {
          this.$message.success("保存成功");
          this.uploadVoucher(false);
        })
        .finally(() => {});
    },

    // 点击凭证， 关闭父组件中的dialog
    uploadVoucher(flag) {
      this.$emit("uploadVoucher", flag);
    },
  },
};
</script>

<style lang="scss" scoped>
.voucherWrap {
  display: flex;
  flex-wrap: wrap;

  .uploadImg {
    width: 200px;
    height: 150px;
    margin-bottom: 10px;
  }

  .item {
    width: 19%;
    margin: 8px 0;
    position: relative;

    .delete {
      cursor: pointer;
      font-size: 20px;
      position: absolute;
      bottom: 15px;
      right: 30px;
      z-index: 9999;
    }

    p {
      width: 200px;
      color: blue;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .placeholder {
    margin: 3% auto 2%;
    font-size: 25px;
    font-weight: bolder;
    color: #ccc;
  }
}

.voucherBtn {
  display: flex;
  justify-content: center;
  margin-top: 1%;
}
</style>
