<template>
  <div class="invoicingCmpt">
    <!-- 主体区域 -->
    <div class="bodyArea">
      <!-- 搜索框 -->
      <el-form
        ref="searchForm"
        inline
        :model="searchForm"
        size="medium"
        label-width="140px"
      >
        <el-form-item
          label="开票申请编号"
          prop="invoiceTaskID"
          label-width="140px"
        >
          <el-input
            v-model="searchForm.invoiceTaskID"
            placeholder="请输入开票申请编号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="业务类型" prop="businessType">
          <el-select
            v-model="searchForm.businessType"
            placeholder="请选择业务类型"
            @change="businessChange"
            clearable
          >
            <el-option
              v-for="item in businessList"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="场景类型" prop="sceneType">
          <el-select
            v-model="searchForm.sceneType"
            placeholder="请选择场景类型"
            clearable
          >
            <el-option
              v-for="item in sceneList"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发票类型" prop="invoiceType">
          <el-select
            v-model="searchForm.invoiceType"
            placeholder="请选择发票类型"
            clearable
          >
            <el-option
              v-for="item in invoiceList"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付方式" prop="payType">
          <el-select v-model="searchForm.payType" placeholder="请选择支付方式">
            <el-option
              v-for="item in payList"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务商单位" prop="consignor">
          <el-input
            v-model="searchForm.consignor"
            placeholder="请输入服务商单位"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="服务商是否已开票" prop="taskStatus">
          <el-select
            v-model="searchForm.taskStatus"
            placeholder="请选择服务商是否已开票"
            clearable
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="未开票" value="0"></el-option>
            <el-option label="已开票" value="1"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="" prop="time">
          <div style="display: flex;">
            <el-dropdown @command="chooseDeteType" trigger="click" placement="bottom">
              <span class="el-dropdown-link">
                <div style="width: 118px; text-align: right;padding-right: 12px; color: #606266; cursor: pointer;white-space: nowrap;">{{searchForm.deteTypeName}}<i style="margin-left: 2px;" class="el-icon-arrow-down">：</i></div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="item" v-for="item in deteTypeList" :key="item.id">{{item.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-date-picker v-model="searchForm.time" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable></el-date-picker>
          </div>
        </el-form-item> -->
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-search"
          @click="search"
          >搜索</el-button
        >
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-delete"
          @click="$refs['searchForm'].resetFields()"
          >清空
        </el-button>
      </el-form>
      <!--数量金额总计-->
      <div
        style="margin: 10px 30px; display: flex; align-items: center"
        v-if="!flag.tableDataLoading"
      >
        <div
          style="
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 10px;
            color: #333;
          "
        >
          <div>
            <span class="money-label">运费总计：</span>
            <span class="money-txt">￥{{ checkList.TotalTransportCosts }}</span>
          </div>
          <div style="margin: 15px 0px">
            <span class="money-label">剩余运费总计：</span>
            <span class="money-txt"
              >￥{{ checkList.TotalTransportCostsSurplus }}</span
            >
          </div>
          <div style="color: rgb(255, 139, 23)">
            <span class="money-label">已退运费总计：</span
            ><span class="money-txt"
              >￥{{ checkList.TotalTransportCostsBack }}</span
            >
          </div>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 10px;
            color: #333;
          "
        >
          <div>
            <span class="money-label">服务费/税费总计：</span>
            <span class="money-txt"
              >￥{{ checkList.TotalExpensesOfTaxation }}</span
            >
          </div>
          <div style="margin: 15px 0px">
            <span class="money-label">剩余服务费/税费总计：</span>
            <span class="money-txt"
              >￥{{ checkList.TotalExpensesOfTaxationSurplus }}</span
            >
          </div>
          <div style="color: rgb(255, 139, 23)">
            <span class="money-label">已退服务费/税费总计：</span>
            <span class="money-txt"
              >￥{{ checkList.TotalExpensesOfTaxationBack }}</span
            >
          </div>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            flex-direction: column;
            color: #333;
          "
        >
          <div>
            <span class="money-label">运单总金额总计：</span>
            <span class="money-txt">￥{{ checkList.TotalNeedPayMoney }}</span>
          </div>
          <div style="margin: 15px 0px">
            <span class="money-label">剩余运单总金额总计：</span>
            <span class="money-txt"
              >￥{{ checkList.TotalNeedPayMoneySurplus }}</span
            >
          </div>
          <div style="color: rgb(255, 139, 23)">
            <span class="money-label">已退运单总金额总计：</span
            ><span class="money-txt"
              >￥{{ checkList.TotalNeedPayMoneyBack }}</span
            >
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <el-table
        :data="tableData"
        v-loading="flag.tableDataLoading"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
      >
        <el-table-column
          fixed
          align="center"
          prop="index"
          label="序号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceTaskID"
          label="开票申请编号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceType"
          label="发票类型"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="billType"
          label="业务类型"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskSceneName"
          label="场景类型"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayModeString"
          label="支付方式"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DemanderName"
          label="货主单位"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="SupplierName"
          label="服务商单位"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column align="center" label="服务商是否已开票" width="200">
          <template slot-scope="scope">
            <el-tag
              v-if="
                scope.row.Disallowance == 0 && scope.row.InvoicePrintDatetime
              "
              type="success"
              >已开票</el-tag
            >
            <el-tag
              v-if="
                scope.row.Disallowance == 0 && !scope.row.InvoicePrintDatetime
              "
              type="danger"
              >未开票</el-tag
            >
            <el-tag v-if="scope.row.Disallowance == 1" type="danger"
              >服务商重新上传发票</el-tag
            >
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="upLoadDatetime" label="导入运单时间" width="200" show-overflow-tooltip></el-table-column> -->
        <el-table-column
          align="center"
          prop="ApplyDatetime"
          label="开票申请时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayDatetime"
          label="确认支付时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoicePrintDatetime"
          label="开票完成日期"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="WaybillCounts"
          label="总计运单数量"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column align="center" label="费率" width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.Rate }}%</p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TransportCosts"
          label="运费"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #333">
              {{ scope.row.TransportCosts | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TransportCosts"
          label="剩余运费"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TransportCostsSurplus | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TransportCosts"
          label="已退运费"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #333">
              {{ scope.row.TransportCostsBack | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="ExpensesOfTaxation"
          label="服务费/税费"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #333">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TransportCosts"
          label="剩余服务费/税费"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.ExpensesOfTaxationSurplus | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TransportCosts"
          label="已退服务费/税费"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #333">
              {{ scope.row.ExpensesOfTaxationBack | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="NeedPayMoney"
          label="运单总金额"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #333">
              {{ scope.row.NeedPayMoney | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TransportCosts"
          label="剩余运单总金额"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.NeedPayMoneySurplus | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TransportCosts"
          label="已退运单总金额"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #333">
              {{ scope.row.NeedPayMoneyBack | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.Disallowance == 0"
              @click="goOn(scope.row)"
              type="primary"
              size="small"
              title="继续"
              >继续</el-button
            >
            <el-button
              v-if="scope.row.Disallowance == 1"
              @click="reject"
              type="warning"
              size="small"
              title="您已驳回"
              >您已驳回
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="openVoucherDialog(scope.row)"
              title="凭证"
              >凭证</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center">
        <!-- 分页 -->
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 上传凭证dialog -->
    <el-dialog
      width="1200px"
      title="凭证"
      center
      :visible.sync="flag.voucherDialog"
      append-to-body
      v-if="flag.voucherDialog"
      :close-on-click-modal="false"
    >
      <EvidenceInfo :businessID="currentInvoiceTaskItem.InvoiceTaskID">
      </EvidenceInfo>
    </el-dialog>
  </div>
</template>

<script>
import { ConsChecking } from "@/api/waybill/pendingManage/index";
import { mapMutations } from "vuex";
import { getDataDict } from "@/api/common/common";
import { getTaskScene } from "@/api/goodsOwner/goodsOwnerManage/basicInfo";
import EvidenceInfo from "@/components/businessCmpt/evidenceInfo";
export default {
  data() {
    return {
      flag: {
        tableDataLoading: false, //是否正在加载表格
        voucherDialog: false, //凭证dialog
      },
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      tableData: [],
      checkList: {}, // 列表接口数据
      searchForm: {
        invoiceTaskID: "", //结算任务单号
        invoiceType: "", //发票类型
        payType: 0, // 支付方式
        businessType: "", //业务类型
        sceneType: "", //场景类型
        consignor: "", //服务商单位
        taskStatus: "", //服务商是否已开票
        time: ["", ""], //时间筛选
        deteType: "", //时间类型
        deteTypeName: "开票申请时间", //时间类型
      },
      deteTypeList: [
        {
          id: 0,
          name: "开票申请时间",
        },
        {
          id: 1,
          name: "开票完成时间",
        },
      ], //时间类型
      sceneList: [], //场景类型
      businessList: [], //业务类型
      invoiceList: [], //发票类型
      payList: [
        { Name: "全部", Code: 0 },
        { Name: "线下支付", Code: 1 },
        { Name: "线上支付", Code: 2 },
      ], // 支付方式
      currentInvoiceTaskItem: {},
    };
  },
  methods: {
    ...mapMutations(["setInvoiceTaskID", "setWaybillType"]),
    init() {
      //获取发票类型
      getDataDict({ type: 20 }).then((res) => {
        this.invoiceList = res.patterSetInfo;
      });
      //获取业务类型
      getDataDict({ type: 27 }).then((res) => {
        this.businessList = res.patterSetInfo;
      });
    },
    //业务类型改变
    businessChange(e) {
      this.searchForm.sceneType = "";
      getTaskScene({ TaskTypeID: e }).then((res) => {
        this.sceneList = res.TaskTypeList.map((item) => {
          return {
            Code: item.TaskSceneTypeID,
            Name: item.TaskSceneName,
          };
        });
      });
    },
    //选择时间类型
    chooseDeteType(e) {
      this.searchForm.deteType = e.id;
      this.searchForm.deteTypeName = e.name;
    },
    //搜索
    search() {
      this.pagination.page = 1;
      this.ConsChecking();
    },
    //提示已驳回
    reject() {
      this.$message.warning("服务商重新上传发票中");
      return;
    },
    //继续
    goOn(item) {
      this.setInvoiceTaskID(item.InvoiceTaskID);
      this.$router.push({
        path: "/waybill/waybillManage/waybillManage",
        query: { step: 4, activeCmpt: 6 },
      });
      // if (item.TaskTypeID == '02') { //1%或3%
      //   if (item.TaskSceneTypeID == 3) { //交通运输
      //     this.setInvoiceTaskID(item.InvoiceTaskID)
      //     this.$router.push({ path: '/waybill/waybillManage/rate3Transport', query: { step: 4 } })
      //   }
      // } else if (item.TaskTypeID == '01') { //9%
      //   if (item.TaskSceneTypeID == 1) { //普货承运
      //     this.setInvoiceTaskID(item.InvoiceTaskID)
      //     this.$router.push({ path: '/waybill/waybillManage/index', query: { step: 4 } })
      //   } else if (item.TaskSceneTypeID == 6) { //土石方
      //     this.setInvoiceTaskID(item.InvoiceTaskID)
      //     this.$router.push({ path: '/waybill/waybillManage/earthworkWaybill', query: { step: 4 } })
      //   }
      // }
    },
    //分页回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.ConsChecking();
    },
    //获取待办列表
    ConsChecking() {
      this.flag.tableDataLoading = true;
      let params = {
        OptionType: 0, //0货主 1服务商
        PayStatus: 1, //支付状态
        TaskStatus: this.searchForm.taskStatus,
        StartDateTime: this.searchForm.time[0],
        EndDateTime: this.searchForm.time[1],
        InvoiceTaskDateTypeEnum: this.searchForm.deteType, //时间类型
        InvoiceType: this.searchForm.invoiceType, //发票类型
        payModeId:
          this.searchForm.payType == 0
            ? []
            : this.searchForm.payType == 1
            ? ["1", "4"]
            : ["2", "3"], // 支付方式
        SceneType: this.searchForm.sceneType, //场景类型
        TaskType: this.searchForm.businessType, //业务类型
        Consignor: this.searchForm.consignor, //服务商名称
        InvoiceTaskID: this.searchForm.invoiceTaskID, //结算单号
        PageIndex: this.pagination.page,
        PageSize: this.pagination.pagesize,
      };
      ConsChecking(params)
        .then((res) => {
          this.checkList = res.checkList || {};
          this.tableData = this.checkList.ConsCheckList.map((item, index) => {
            item.index = index + 1;
            return item;
          });

          if (this.tableData && this.tableData[0]) {
            this.setWaybillType({
              taskType: this.tableData[0].billType,
              taskTypeId: this.tableData[0].TaskTypeID,
              rateType: this.tableData[0].InvoiceType,
              sceneType: this.tableData[0].TaskSceneName,
              sceneId: this.tableData[0].TaskSceneTypeID,
              waybillList: [],
            });
          }

          this.pagination.total = Number(this.checkList.count);
        })
        .finally(() => {
          this.flag.tableDataLoading = false;
        });
    },
    //打开上传凭证弹窗
    openVoucherDialog(item) {
      this.flag.voucherDialog = true;
      this.currentInvoiceTaskItem = item;
    },
  },
  created() {
    this.init();
    this.ConsChecking();
  },
  components: {
    EvidenceInfo,
  },
};
</script>

<style lang="scss" scoped>
.invoicingCmpt {
  .bodyArea {
    .pagination {
      margin-top: 10px;
    }
  }
}

.money-label {
  display: inline-block;
  width: 150px;
  text-align: right;
}

.money-txt {
  display: inline-block;
  width: 200px;
  text-align: left;
}
</style>
