import axios from '@/api/config/interceptor'
import { host, riskHost } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'


//上传凭证
export const addEvidence = (params,id) => { 
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/BillLibrary/OfflineVoucherIFormFile/${id}`, params).then(res => { 
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//保存凭证
export const preserveEvidence = (params,id) => { 
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/BillLibrary/OfflineVoucherUrl/${id}`, params).then(res => { 
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取凭证
export const getEvidence = (params,id) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/BillLibrary/OfflinePageLis/${id}`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//下载凭证 - 后台返回文件流格式
export const downEvidenceStream = (params,type) => { 
    console.log('params',params)
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/BillLibrary/DownloadOfflineVouchers/${params}`,type).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}