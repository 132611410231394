<template>
  <div class="penddingManage">
    <div class="facilityBox" style="position: relative">
      <el-tabs v-model="activeCmpt" @tab-click="cmptChange">
        <el-tab-pane label="正在申请" name="3"></el-tab-pane>
        <el-tab-pane label="确认付款" name="5"></el-tab-pane>
        <el-tab-pane label="核对发票" name="6"></el-tab-pane>
      </el-tabs>

      <!-- 温馨提示 -->
      <div style="position: absolute; top: 17px; right: 16px">
        <el-tooltip placement="left">
          <div slot="content">
            1.您所导入的每一批次运单表格，按导入时间排序<br />
            2.您可以清楚的看到进度状态<br />
            3.每一批次运单的右侧有“继续”按钮，点击即可跳转<br />
            4.待办理的运单，包含任何场景类型，按4个步骤分类
          </div>
          <div style="margin: 0px 20px; color: #0f5fff; cursor: pointer">
            <i class="el-icon-question"></i>
            <span>帮助</span>
          </div>
        </el-tooltip>
      </div>

      <Wait v-if="activeCmpt == 3" @uploadVoucher="uploadVoucher"></Wait>
      <Payment v-if="activeCmpt == 5" @uploadVoucher="uploadVoucher"></Payment>
      <Agree v-if="activeCmpt == 6" @uploadVoucher="uploadVoucher"></Agree>

      <el-dialog
        width="1200px"
        title="线下付款凭证"
        center
        :visible.sync="dialogVisible"
      >
        <UploadFile
          ref="uploadFile"
          @uploadVoucher="uploadVoucher"
        ></UploadFile>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Agree from "./cmpt/agree";
import Payment from "./cmpt/payment";
import Wait from "./cmpt/wait";

import UploadFile from "@/components/uploadFile/uploadFile";

export default {
  data() {
    return {
      activeCmpt: "3", //当前激活哪个tabs页
      dialogVisible: false,
    };
  },
  created() {
    this.activeCmpt = this.$route.query.activeCmpt
      ? this.$route.query.activeCmpt
      : "3";
  },
  methods: {
    //tabs页面跳转
    cmptChange(e) {
      this.activeCmpt = e.name;
    },

    // 打开凭证dialog
    uploadVoucher(e) {
      this.dialogVisible = e;

      this.$nextTick(function () {
        this.$refs.uploadFile.getUploadFile();
      });
    },
  },
  components: {
    Agree,
    Payment,
    Wait,
    UploadFile,
  },
};
</script>

<style lang="scss" scoped></style>
